import React from 'react'
import { Link } from "gatsby"
import htmlDecode from "html-entities-decoder"

import "./ServicesList.css";


function ServicesList(props) {
    const data = props;
    console.log(data)

    return (
        <div className="sv-sticky-container hidden lg:block hidden  md:w-1/4">
            {data.list.map((cat,index) => (
                <div key={`nav-cat-${index}`} className={index > 0 ? "mt-8" : "mt-0"}>
                    <h4 className="sv-nav-header">
                        {cat.node.name}
                    </h4>
                    <ul>
                        { cat.node.services.edges.map((service,yndex) => (
                            <li key={`nav-serv-${yndex}`} className="sv-li" style={ data.currentPage === service.node.uri ? { color: "#1d40ca"} : { color: "inherit"} }>
                                <Link to={service.node.uri} >
                                    {htmlDecode(service.node.title)}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    )
}

export default ServicesList;
import React, { useState } from 'react'
import "./ServiceContent.css" ;

function ServiceContent(props) {
    console.log(props)
    const data = props;

    return (
        <div className=" w-full md:w-3/4 pb-4">
            <div className="p-4 service-editor" dangerouslySetInnerHTML={{__html: data.content }} />
        </div>
    )
}

export default ServiceContent;
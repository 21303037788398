import React from "react"
import { graphql, Link } from "gatsby"

import Layout from '../components/layout'
// import Introduction from "../components/Service/Introduction"
import ServiceContent from "../components/Service/ServiceContent"
import ServicesList from "../components/Service/ServicesList"
import Breadcrumb from "../components/Global/Breadcrumb"
import Main from "../components/CaseStudies/Main"




function ServiceTemplate(props) {
    let data = props.data.wpgraphql;

    console.log(data)

    function getBreadcrumb() {
        let bCrumb = [];
        bCrumb.push({
            text: "Home",
            url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : "/"}`
        })
        bCrumb.push({
            text: data.service.serviceCategories.edges[0].node.breadcrumbServicesAcf.breadcrumbName,
            url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : ""}/${data.service.serviceCategories.edges[0].node.breadcrumbServicesAcf.breadcrumbSlug}/`
        })
        bCrumb.push({
            text: data.service.breadcrumbAcf.breadcrumbName,
        })
        return bCrumb;
    }

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://enimeris.com${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://enimeris.com${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <div>
            <Layout
                headerMenu={props.pageContext.headerMenu}
                footerMenu={props.pageContext.footerMenu}
                currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
                availableVersions={props.pageContext.availablePages}
                metaData = {constructMetaData(data.service, props.pageContext.currentPage, props.pageContext.language)}
                services={props.pageContext.servicesCategories}
            >
                <div>
                    {/* <section>
                        {data.service.servicesAcf.featuredImage && (
                            <Introduction backImg={data.service.servicesAcf.featuredImage} title={data.service.title} />
                        )}
                    </section> */}
                    {data.service.breadcrumbAcf.breadcrumbName && (
                        <section>
                            <Breadcrumb list={getBreadcrumb()} />
                        </section>
                    )}
                    <section>
                        <Main backImg={data.service.servicesAcf.featuredImage} content={data.service.servicesAcf.introductionParagraph} title={data.service.title} />
                    </section>
                    <section className="container flex flex-wrap m-auto justify-center md:my-10">
                        <ServiceContent content={data.service.content} />
                        <ServicesList list={data.serviceCategories.edges} currentPage={data.service.uri} />
                    </section>
                </div>
            </Layout>
        </div>

    )
}

export default ServiceTemplate

export const pageQuery = graphql`
    query GET_SERVICE($id: ID! , $language: WPGraphQL_LanguageCodeFilterEnum) {
        wpgraphql {
            service(id: $id){
                seo {
                    canonical
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }
                breadcrumbAcf{
                    breadcrumbName
                }
                serviceCategories{
                    edges{
                        node{
                            breadcrumbServicesAcf{
                                breadcrumbName
                                breadcrumbSlug
                            }
                        }
                    }
                }
                title
                content
                uri
                servicesAcf{
                    introductionParagraph
                    featuredImage{
                        sourceUrl
                        altText
                        imageFile{
                            childImageSharp{
                                fluid(maxWidth: 2080, quality:80){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                }
            }
            serviceCategories(where: {language: $language}) {
                edges {
                    node {
                      name
                      services (first: 200, where: {orderby: {field: MENU_ORDER, order: ASC}}) {
                        edges {
                          node {
                            id
                            uri
                            title
                          }
                        }
                      }
                    }
                }
            }
        }
    }
`